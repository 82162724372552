import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import calendar from '@images/art/calendario-edicion-ii.png';
import calendarBig from '@images/art/calendario-edicion-ii.png';

const Information = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="informacion" accent="secondary">
        <StyledContainer>
          <div style={{ marginBottom: 80 }}>
            <h2>
              <span role="img" aria-label="calendario">
                📆
              </span>{' '}
              ¿Cuándo empieza?
            </h2>
            <p>
              Ya estoy pensando fechas para{' '}
              <strong>la siguiente edición</strong> de Latte and Front. Si no
              quieres perdértela, escríbeme a{' '}
              <a href="mailto:info@latteandcode.com" target="_blank">
                info@latteandcode.com
              </a>
            </p>
          </div>
          <div>
            <h2>Formato Latte and Front</h2>
            <p>
              Quiero que aproveches esta formación al máximo, que puedas
              aprender sin prisa, pero sin pausa.
            </p>
            <p>
              <b>¡Ésto es lo que te propongo!</b>
            </p>
            <ul>
              <li>
                <p>
                  8 semanas de formación (2 semanas de Javascript + 3 semanas de
                  React + 1 semana ¡Más que código! + 2 semanas de proyecto
                  final).
                </p>
              </li>
              <li>
                <p>
                  Acceso a la plataforma con el material exclusivo que he
                  preparado para esta mentoría.
                </p>
                <p>
                  Cada semana tendrás nuevo material para que lo prepares a tu
                  ritmo.
                </p>
              </li>
              <li>
                <p>
                  Para que no te quedes con ninguna duda semanalmente tendremos:
                </p>
                <ul>
                  <li>
                    <p>1 sesión grupal de preguntas y respuestas en directo.</p>
                  </li>
                  <li>
                    <p>
                      1 sesión grabada respondiendo todas las dudas que me
                      envíes.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Sesión de despedida + documento personalizado.</p>
              </li>
              <li>
                <p>Acceso grupo exclusivo en Telegram.</p>
              </li>
            </ul>
          </div>
          <div>
            <h3>Además...</h3>
            <ul>
              <li>
                <p>
                  Promoción de tu perfil en mi Linkedin (+ de 20.000
                  seguidores).
                </p>
              </li>
              <li>
                <p>Bolsa de empleo de Latte and Code.</p>
              </li>
              <li>
                <p>
                  Acceso al grupo de Telegram de por vida: se compartirán
                  noticias, ofertas de trabajo y contenido de interés para todos
                  los participantes.
                </p>
              </li>
              <li>
                <p>
                  Al finalizar el curso, podrás acceder a todo el contenido para
                  releerlo siempre que quieras. El contenido además estará
                  siempre actualizado con las últimas novedades de Javascript{' '}
                  <span role="img" aria-label="guiño">
                    😉
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <Art>
            <Img fluid={data.art_team.childImageSharp.fluid} />
          </Art>
          <FinalQuote>
            "Imprescindible para cualquier desarrollador Front!".
            <br />
            <small>Alumno de la I Edición</small>
          </FinalQuote>
          <FinalQuote>
            Hola Gerardo, solo te escribo para decirte que me está flipando (y a
            muchos compis tb) la forma que tienes de enseñar.
            <br />
            <small>Alumno de Assembler School</small>
          </FinalQuote>
          <FinalQuote>
            Nos ha encantado Latte and Front. ¿Podríamos extenderlo un par de
            semanas más para seguir aprendiendo contigo?
            <br />
            <small>Alumno de la I Edición</small>
          </FinalQuote>
          <ArtMobile>
            <Img fluid={data.art_team.childImageSharp.fluid} />
          </ArtMobile>
        </StyledContainer>
      </Section>
    )}
  />
);

// const Information = () => (
//   <StaticQuery
//     query={graphql`
//       query {
//         allFile(filter: { sourceInstanceName: { eq: "team" } }) {
//           edges {
//             node {
//               relativePath
//               childImageSharp {
//                 fluid(maxWidth: 400, maxHeight: 400) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//         art_team: file(
//           sourceInstanceName: { eq: "art" }
//           name: { eq: "team_work" }
//         ) {
//           childImageSharp {
//             fluid(maxWidth: 1600) {
//               ...GatsbyImageSharpFluid_withWebp_tracedSVG
//             }
//           }
//         }
//       }
//     `}
//     render={data => (
//       <Section id="informacion" accent="secondary">
//         <StyledContainer>
//           <div style={{ marginBottom: 80 }}>
//             <h2>
//               <span role="img" aria-label="calendario">
//                 📆
//               </span>{' '}
//               ¿Cuándo empieza?
//             </h2>
//             <p>
//               El <strong>18 de abril</strong> nos ponemos manos a la obra
//               dispuestos a comernos el mundo <i>front</i>.
//             </p>
//             <p>
//               La <strong>duración será de 8 semanas</strong>.
//             </p>
//             <a href={calendarBig} target="_blank" rel="noreferrer">
//               <CalendarImage src={calendar} />
//             </a>
//             <p>
//               <small>
//                 <span role="img" aria-label="mirar hacia arriba">
//                   👆
//                 </span>{' '}
//                 Puedes hacer click en la imagen para ampliarla
//               </small>
//             </p>
//           </div>
//           <div>
//             <h2>Formato Latte and Front</h2>
//             <p>
//               Quiero que aproveches esta formación al máximo, que puedas
//               aprender sin prisa, pero sin pausa.
//             </p>
//             <p>
//               <b>¡Ésto es lo que te propongo!</b>
//             </p>
//             <ul>
//               <li>
//                 <p>
//                   8 semanas de formación (2 semanas de Javascript + 3 semanas de
//                   React + 1 semana ¡Más que código! + 2 semanas de proyecto
//                   final).
//                 </p>
//               </li>
//               <li>
//                 <p>
//                   Acceso a la plataforma con el material exclusivo que he
//                   preparado para esta mentoría.
//                 </p>
//                 <p>
//                   Cada semana tendrás nuevo material para que lo prepares a tu
//                   ritmo.
//                 </p>
//               </li>
//               <li>
//                 <p>
//                   Para que no te quedes con ninguna duda semanalmente tendremos:
//                 </p>
//                 <ul>
//                   <li>
//                     <p>1 sesión grupal de preguntas y respuestas en directo.</p>
//                   </li>
//                   <li>
//                     <p>
//                       1 sesión grabada respondiendo todas las dudas que me
//                       envíes.
//                     </p>
//                   </li>
//                 </ul>
//               </li>
//               <li>
//                 <p>Sesión de despedida + documento personalizado.</p>
//               </li>
//               <li>
//                 <p>Acceso grupo exclusivo en Telegram.</p>
//               </li>
//             </ul>
//           </div>
//           <div>
//             <h3>Además...</h3>
//             <ul>
//               <li>
//                 <p>
//                   Promoción de tu perfil en mi Linkedin (+ de 20.000
//                   seguidores).
//                 </p>
//               </li>
//               <li>
//                 <p>Bolsa de empleo de Latte and Code.</p>
//               </li>
//               <li>
//                 <p>
//                   Acceso al grupo de Telegram de por vida: se compartirán
//                   noticias, ofertas de trabajo y contenido de interés para todos
//                   los participantes.
//                 </p>
//               </li>
//               <li>
//                 <p>
//                   Al finalizar el curso, podrás acceder a todo el contenido para
//                   releerlo siempre que quieras. El contenido además estará
//                   siempre actualizado con las últimas novedades de Javascript{' '}
//                   <span role="img" aria-label="guiño">
//                     😉
//                   </span>
//                 </p>
//               </li>
//             </ul>
//           </div>
//           <div>
//             <h3>Extra segunda edición</h3>
//             <ul>
//               <li>
//                 <p>Acceso a vídeos exclusivos de Latte and Front.</p>
//               </li>
//             </ul>
//           </div>
//           <Art>
//             <Img fluid={data.art_team.childImageSharp.fluid} />
//           </Art>
//           <FinalQuote>
//             "Imprescindible para cualquier desarrollador Front!".
//             <br />
//             <small>Alumno de la I Edición</small>
//           </FinalQuote>
//           <FinalQuote>
//             Hola Gerardo, solo te escribo para decirte que me está flipando (y a
//             muchos compis tb) la forma que tienes de enseñar.
//             <br />
//             <small>Alumno de Assembler School</small>
//           </FinalQuote>
//           <FinalQuote>
//             Nos ha encantado Latte and Front. ¿Podríamos extenderlo un par de
//             semanas más para seguir aprendiendo contigo?
//             <br />
//             <small>Alumno de la I Edición</small>
//           </FinalQuote>
//           <ArtMobile>
//             <Img fluid={data.art_team.childImageSharp.fluid} />
//           </ArtMobile>
//         </StyledContainer>
//       </Section>
//     )}
//   />
// );

const FinalQuote = styled.h2`
  margin-top: 32px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.2;

  @media (min-width: ${props => props.theme.screen.md}) {
    font-size: 20px;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;

  p {
    margin-bottom: 16px;
  }

  h2 {
    color: #000;
    margin-bottom: 24px;
    font-weight: bold;
  }

  & > div {
    margin-bottom: 48px;

    @media (min-width: ${props => props.theme.screen.md}) {
      width: 70%;
    }
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 20%;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const CalendarImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  filter: brightness(1.1);
  mix-blend-mode: multiply;

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-left: -40px;
  }
`;

export default Information;
