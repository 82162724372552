import React, { useCallback, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useFormik } from 'formik';

import { Section, Container } from '@components/global';

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Introduce un email válido';
  }

  if (!values.fullname) {
    errors.fullname = 'Requerido';
  }

  return errors;
};

const initialValues = {
  fullname: '',
  email: '',
  telephone: '',
  message: ''
};

const SENDING_STATE = {
  EMPTY: 0,
  SENDING: 1,
  SUCCESSFULL: 2,
  ERROR: 3
};

// const Contact = function() {
//   const [sendingState, setSendingState] = useState(SENDING_STATE.EMPTY);

//   const sendForm = useCallback(
//     async function(values) {
//       try {
//         setSendingState(SENDING_STATE.SENDING);
//         const formData = new FormData();
//         for (const key in values) {
//           formData.append(key, values[key]);
//         }
//         const response = await fetch(
//           'https://getform.io/f/a400a36b-f956-4153-906a-52f4a1393e2e',
//           {
//             method: 'POST',
//             body: formData,
//             headers: {
//               Accept: 'application/json'
//             },
//             crossDomain: true
//           }
//         );
//         if (response.ok) {
//           const json = await response.json();
//           console.log(json);
//           setSendingState(SENDING_STATE.SUCCESSFULL);
//         } else {
//           setSendingState(SENDING_STATE.ERROR);
//         }
//       } catch (error) {
//         setSendingState(SENDING_STATE.ERROR);
//       }
//     },
//     [setSendingState]
//   );
//   const formik = useFormik({
//     initialValues,
//     validate,
//     onSubmit: sendForm
//   });

//   return (
//     <StaticQuery
//       query={graphql`
//         query {
//           art_ideas: file(
//             sourceInstanceName: { eq: "art" }
//             name: { eq: "ideas" }
//           ) {
//             childImageSharp {
//               fluid(maxWidth: 760) {
//                 ...GatsbyImageSharpFluid_withWebp_tracedSVG
//               }
//             }
//           }
//         }
//       `}
//       render={data => (
//         <Section id="inscribeme" accent="secondary">
//           <Container>
//             <Grid>
//               <div>
//                 <Title>¿Te unes a Latte and Front?</Title>
//                 <Subtitle>
//                   Déjanos tus datos y te enviaremos los pasos para inscribirte
//                 </Subtitle>
//                 <FormWrapper>
//                   <form onSubmit={formik.handleSubmit}>
//                     <FormGroup>
//                       <Label htmlFor="firstName">Nombre completo (*)</Label>
//                       <FormControl
//                         id="fullname"
//                         name="fullname"
//                         type="text"
//                         onChange={formik.handleChange}
//                         value={formik.values.fullname}
//                         hasError={
//                           !!formik.errors.fullname && formik.touched.fullname
//                         }
//                       />
//                       {!!formik.errors.fullname && formik.touched.fullname && (
//                         <InvalidFeedback>
//                           {formik.errors.fullname}
//                         </InvalidFeedback>
//                       )}
//                     </FormGroup>
//                     <FormGroup>
//                       <Label htmlFor="email">Email (*)</Label>
//                       <FormControl
//                         id="email"
//                         name="email"
//                         type="email"
//                         onChange={formik.handleChange}
//                         value={formik.values.email}
//                         hasError={!!formik.errors.email && formik.touched.email}
//                       />
//                       {!!formik.errors.email && formik.touched.email && (
//                         <InvalidFeedback>{formik.errors.email}</InvalidFeedback>
//                       )}
//                     </FormGroup>
//                     <FormGroup>
//                       <Label htmlFor="telephone">Teléfono</Label>
//                       <FormControl
//                         id="telephone"
//                         name="telephone"
//                         type="telephone"
//                         onChange={formik.handleChange}
//                         value={formik.values.telephone}
//                       />
//                     </FormGroup>
//                     <FormGroup>
//                       <Label htmlFor="telephone">
//                         Escribe aquí cualquier duda que tengas
//                       </Label>
//                       <Textarea
//                         id="message"
//                         name="message"
//                         type="message"
//                         onChange={formik.handleChange}
//                         value={formik.values.message}
//                         rows="5"
//                       />
//                     </FormGroup>
//                     <Button type="submit">¡Me apunto!</Button>
//                     {sendingState === SENDING_STATE.SENDING && (
//                       <Status variant="info">
//                         <p>Enviando tu formulario...</p>
//                       </Status>
//                     )}
//                     {sendingState === SENDING_STATE.SUCCESSFULL && (
//                       <Status variant="success">
//                         <p>
//                           <span role="img" aria-label="¡Bien!">
//                             🎉
//                           </span>{' '}
//                           ¡Enhorabuena! He recibido correctamente tu solicitud.
//                           A lo largo del día me pondré en contacto contigo para
//                           contarte los siguientes pasos.
//                         </p>
//                         <p>
//                           ¡Muchísimas gracias por confiar en mí para seguir
//                           aprendiendo!
//                         </p>
//                       </Status>
//                     )}
//                     {sendingState === SENDING_STATE.ERROR && (
//                       <Status>
//                         <p>
//                           ¡Pues vaya! Ha ocurrido un error mientras se enviaba
//                           tu formulario. Si vuelves a intentarlo y te vuelve a
//                           suceder por favor, escríbeme a{' '}
//                           <a href="mailto:info@latteandcode.com">
//                             info@latteandcode.com
//                           </a>{' '}
//                           para que pueda enviarte la información
//                         </p>
//                       </Status>
//                     )}
//                   </form>
//                 </FormWrapper>
//                 <p>
//                   <small>
//                     *He decidido que las{' '}
//                     <b>
//                       <u>plazas disponibles sean limitadas</u>
//                     </b>{' '}
//                     para que la mentoría sea 100% productiva.
//                   </small>
//                 </p>
//                 <p>
//                   <small>
//                     *Inscripciones abiertas hasta el 1 de abril o fin de plazas.
//                   </small>
//                 </p>
//               </div>
//               <Art>
//                 <Img fluid={data.art_ideas.childImageSharp.fluid} />
//               </Art>
//             </Grid>
//           </Container>
//           <Container style={{ marginTop: 48 }}>
//             <h2 style={{ marginBottom: 16 }}>
//               Testimonios de la primera edición
//             </h2>
//             <Testimonial>
//               <p className="text">
//                 <i>
//                   "A mí se me ha abierto un mundo de posibilidades con todo lo
//                   que he aprendido. Gerardo comparte lo que sabe sin límite y su
//                   objetivo siempre ha sido que aprendamos lo máximo posible.
//                   Explica lo necesario y mucho más, además que su motivación es
//                   contagiosa. Siento que lo que ofrece tiene un valor
//                   incalculable, se notan los años de experiencia y sus ganas de
//                   compartir conocimiento. Así da gusto :)"
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="text">
//                 <i>
//                   "Mentoría espectacular, en la que Gerardo, además de
//                   ofrecernos un material muy cuidado y muy didáctico, nos ha
//                   acompañado cada semana, resolviendo dudas y explicando partes
//                   más difíciles. Sin duda no me arrepiento y ojalá haya una
//                   segunda parte!"
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="text">
//                 <i>
//                   "Destacaría de Gerardo su actitud, su proactividad y lo
//                   resolutivo que es. Consiguiendo que Latte and Front sea algo
//                   imprescindible en cualquier desarrollador front."
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="text">
//                 <i>
//                   "Sin duda alguna un curso excelente, contenido de calidad,
//                   directo y sin ser abrumador; lleno de ejemplos para comprender
//                   mejor lo que aprendes y ejercicios para asimilar bien los
//                   conceptos. Apoyo constante por parte del tutor tanto por el
//                   canal de Telegram como con las sesiones para resolver dudas en
//                   diferido y en "live". Un curso muy bueno y recomendado."
//                 </i>
//               </p>
//             </Testimonial>
//           </Container>
//           <Container style={{ marginTop: 48 }}>
//             <h2 style={{ marginBottom: 16 }}>Dicen de mí...</h2>
//             <Testimonial>
//               <p className="name">
//                 <a
//                   href="https://youtu.be/R5oXW5ryOGU"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   César L.
//                 </a>
//               </p>
//               <p className="text">
//                 <i>
//                   "Mis conocimientos de programación son prácticamente nulos.
//                   Hace unos días empecé a seguir un curso de Javascript por
//                   diversión y mi primer bloqueo llegó con el this. Busco por
//                   Youtube, veo varios vídeos y me tropiezo con este canal de
//                   solo 461 suscriptores, vídeo con menos de 100 reproducciones y
//                   limpio de comentarios. Pienso voy a ver el vídeo porque habla
//                   del this y he flipado ... sin exagerar nada. Excelente
//                   claridad en la explicación y si yo he entendido algo entonces
//                   quién tenga más conocimientos lo exprimirá. Gracias por el
//                   vídeo."
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="name">
//                 <a
//                   href="https://www.youtube.com/watch?v=yl0rqdYz_eQ&lc=Ugx6806pK5weXszhuNV4AaABAg.97xojZo6nzW97xsEE_vv8u"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   Sara Enrique
//                 </a>
//               </p>
//               <p className="text">
//                 <i>
//                   "Muchísimas gracias por tu recomendación y por los enlaces,
//                   voy a echar un ojo! Y me reitero, sigue así, mucho animo con
//                   el canal, se te da muy bien explicar todos estos conceptos
//                   técnicos que sin duda alguna no son fáciles de transmitir con
//                   esa naturalidad. A tope! :)"
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="name">Alumno I Edición</p>
//               <p className="text">
//                 <i>
//                   "Gerardo es un crack profesionalmente a nivel de materia y
//                   dotes de docente."
//                 </i>
//               </p>
//             </Testimonial>
//             <Testimonial>
//               <p className="name">
//                 <a
//                   href="https://www.youtube.com/watch?v=eGhydFxUv8M&lc=UgxW8msyPMBbWhFBZCB4AaABAg"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   Laura G.
//                 </a>
//               </p>
//               <p className="text">
//                 <i>
//                   "Me encanta, muy necesario este vídeo. Acabo de terminar un
//                   bootcamp de desarrollo front y hay taaaanto por hacer que es
//                   abrumador!"
//                 </i>
//               </p>
//             </Testimonial>
//           </Container>
//         </Section>
//       )}
//     />
//   );
// };

const Contact = function() {
  const [sendingState, setSendingState] = useState(SENDING_STATE.EMPTY);

  const sendForm = useCallback(
    async function(values) {
      try {
        setSendingState(SENDING_STATE.SENDING);
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }
        const response = await fetch(
          'https://getform.io/f/a400a36b-f956-4153-906a-52f4a1393e2e',
          {
            method: 'POST',
            body: formData,
            headers: {
              Accept: 'application/json'
            },
            crossDomain: true
          }
        );
        if (response.ok) {
          const json = await response.json();
          console.log(json);
          setSendingState(SENDING_STATE.SUCCESSFULL);
        } else {
          setSendingState(SENDING_STATE.ERROR);
        }
      } catch (error) {
        setSendingState(SENDING_STATE.ERROR);
      }
    },
    [setSendingState]
  );
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: sendForm
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          art_ideas: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "ideas" }
          ) {
            childImageSharp {
              fluid(maxWidth: 760) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <Section id="inscribeme" accent="secondary">
          <Container>
            <Grid>
              <div>
                <Title>¿Te unes a Latte and Front?</Title>
                <Subtitle>
                  Escríbeme y te enviaré la información para la siguiente
                  edición de Latte and Front:
                </Subtitle>
                <FormWrapper>
                  <p>
                    <a href="mailto:info@latteandcode.com" target="_blank">
                      info@latteandcode.com
                    </a>
                  </p>
                </FormWrapper>
                <p>
                  <small>
                    *He decidido que las{' '}
                    <b>
                      <u>plazas disponibles sean limitadas</u>
                    </b>{' '}
                    para que la mentoría sea 100% productiva.
                  </small>
                </p>
                <p>
                  <small>
                    *Inscripciones abiertas hasta el 1 de abril o fin de plazas.
                  </small>
                </p>
              </div>
              <Art>
                <Img fluid={data.art_ideas.childImageSharp.fluid} />
              </Art>
            </Grid>
          </Container>
          <Container style={{ marginTop: 48 }}>
            <h2 style={{ marginBottom: 16 }}>
              Testimonios de la primera edición
            </h2>
            <Testimonial>
              <p className="text">
                <i>
                  "A mí se me ha abierto un mundo de posibilidades con todo lo
                  que he aprendido. Gerardo comparte lo que sabe sin límite y su
                  objetivo siempre ha sido que aprendamos lo máximo posible.
                  Explica lo necesario y mucho más, además que su motivación es
                  contagiosa. Siento que lo que ofrece tiene un valor
                  incalculable, se notan los años de experiencia y sus ganas de
                  compartir conocimiento. Así da gusto :)"
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="text">
                <i>
                  "Mentoría espectacular, en la que Gerardo, además de
                  ofrecernos un material muy cuidado y muy didáctico, nos ha
                  acompañado cada semana, resolviendo dudas y explicando partes
                  más difíciles. Sin duda no me arrepiento y ojalá haya una
                  segunda parte!"
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="text">
                <i>
                  "Destacaría de Gerardo su actitud, su proactividad y lo
                  resolutivo que es. Consiguiendo que Latte and Front sea algo
                  imprescindible en cualquier desarrollador front."
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="text">
                <i>
                  "Sin duda alguna un curso excelente, contenido de calidad,
                  directo y sin ser abrumador; lleno de ejemplos para comprender
                  mejor lo que aprendes y ejercicios para asimilar bien los
                  conceptos. Apoyo constante por parte del tutor tanto por el
                  canal de Telegram como con las sesiones para resolver dudas en
                  diferido y en "live". Un curso muy bueno y recomendado."
                </i>
              </p>
            </Testimonial>
          </Container>
          <Container style={{ marginTop: 48 }}>
            <h2 style={{ marginBottom: 16 }}>Dicen de mí...</h2>
            <Testimonial>
              <p className="name">
                <a
                  href="https://youtu.be/R5oXW5ryOGU"
                  target="_blank"
                  rel="noreferrer"
                >
                  César L.
                </a>
              </p>
              <p className="text">
                <i>
                  "Mis conocimientos de programación son prácticamente nulos.
                  Hace unos días empecé a seguir un curso de Javascript por
                  diversión y mi primer bloqueo llegó con el this. Busco por
                  Youtube, veo varios vídeos y me tropiezo con este canal de
                  solo 461 suscriptores, vídeo con menos de 100 reproducciones y
                  limpio de comentarios. Pienso voy a ver el vídeo porque habla
                  del this y he flipado ... sin exagerar nada. Excelente
                  claridad en la explicación y si yo he entendido algo entonces
                  quién tenga más conocimientos lo exprimirá. Gracias por el
                  vídeo."
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="name">
                <a
                  href="https://www.youtube.com/watch?v=yl0rqdYz_eQ&lc=Ugx6806pK5weXszhuNV4AaABAg.97xojZo6nzW97xsEE_vv8u"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sara Enrique
                </a>
              </p>
              <p className="text">
                <i>
                  "Muchísimas gracias por tu recomendación y por los enlaces,
                  voy a echar un ojo! Y me reitero, sigue así, mucho animo con
                  el canal, se te da muy bien explicar todos estos conceptos
                  técnicos que sin duda alguna no son fáciles de transmitir con
                  esa naturalidad. A tope! :)"
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="name">Alumno I Edición</p>
              <p className="text">
                <i>
                  "Gerardo es un crack profesionalmente a nivel de materia y
                  dotes de docente."
                </i>
              </p>
            </Testimonial>
            <Testimonial>
              <p className="name">
                <a
                  href="https://www.youtube.com/watch?v=eGhydFxUv8M&lc=UgxW8msyPMBbWhFBZCB4AaABAg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Laura G.
                </a>
              </p>
              <p className="text">
                <i>
                  "Me encanta, muy necesario este vídeo. Acabo de terminar un
                  bootcamp de desarrollo front y hay taaaanto por hacer que es
                  abrumador!"
                </i>
              </p>
            </Testimonial>
          </Container>
        </Section>
      )}
    />
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 64px 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const Art = styled.figure`
  display: none;
  margin: 0;
  max-width: 380px;
  width: 100%;
  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.h1`
  margin-bottom: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  margin-bottom: 32px;
`;

const FormWrapper = styled.div`
  margin-bottom: 32px;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const FormControl = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${props => (props.hasError ? '#dc3545' : '#ced4da')};
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: auto;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${props => (props.hasError ? '#dc3545' : '#ced4da')};
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

const Button = styled.button`
  padding: 15px;
  color: white;
  background: ${props => props.theme.color.primary};
  border-style: solid;
  border-color: ${props => props.theme.color.primary};
  font-weight: bold;
  font-size: 24px;
  border-radius: 4px;
`;

const Status = styled.div`
  position: relative;
  margin-top: 32px;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;

  color: ${props => {
    if (props.variant === 'info') {
      return '#004085';
    } else if (props.variant === 'success') {
      return '#155724';
    } else if (props.variant === 'error') {
      return '#721c24';
    }
  }};
  background-color: ${props => {
    if (props.variant === 'info') {
      return '#cce5ff';
    } else if (props.variant === 'success') {
      return '#d4edda';
    } else if (props.variant === 'error') {
      return '#f8d7da';
    }
  }};
  border-color: ${props => {
    if (props.variant === 'info') {
      return '#b8daff';
    } else if (props.variant === 'success') {
      return '#c3e6cb';
    } else if (props.variant === 'error') {
      return '#f5c6cb';
    }
  }};
`;

const Testimonial = styled.div`
  margin-bottom: 32px;

  .name {
    font-weight: bold;
    margin-bottom: 8px;
    text-decoration: underline;
    color: ${props => props.theme.color.text};

    a {
      color: ${props => props.theme.color.text};
    }
  }

  .text {
    ${props => props.theme.font_size.xsmall};
  }
`;

export default Contact;
