import React from 'react';
import { Section, Container } from '@components/global';
import FaqItem from '@common/FaqItem';

const FAQS = [
  {
    title: '¿Cómo está estructurado el contenido?',
    content: () => (
      <>
        <p>
          Al comienzo del curso recibirás el acceso a una plataforma online
          donde iré publicando cada semana el contenido. Éste aparecerá poco a
          poco, para dosificarlo y que realmente aproveches la formación.
        </p>
      </>
    ),
  },
  {
    title: '¿En qué consiste la sesión de preguntas y respuestas?',
    content: () => (
      <p>
        Una sesión online semanal de una hora con todo el grupo a través de una plataforma
        virtual (antes de la sesión, te enviaré el enlace para que puedas
        conectarte), para poder resolver dudas y ayudarte a comprender todo el
        material del curso.
      </p>
    ),
  },
  {
    title: '¿En qué consiste la sesión grabada de dudas?',
    content: () => (
      <>
        <p>
          Además de la sesión de preguntas y respuestas, tendrás la posibilidad de enviar tus dudas
          puntuales y 1 vez a la semana, recibirás un vídeo donde responderé a
          tus preguntas y a las del resto de los compañeros.
        </p>
        <p>Podrás escribir tus dudas a través de un documento compartido.</p>
      </>
    ),
  },
  {
    title: '¿Puedo contactar con Gerardo siempre que quiera?',
    content: () => (
      <p>
        Latte and Front tiene un formato flexible para que te puedas organizar como
        prefieras así que la comunicación será muy fluida. La sesión semanal de
        Q&A, la grabación respondiendo dudas y el grupo de Telegram, te
        permitirá aprovechar al máximo la formación y resolver tus bloqueos de
        forma ágil.
      </p>
    ),
  },
  {
    title: '¿En qué consiste el grupo de Telegram?',
    content: () => (
      <p>
        Es un grupo privado, para los integrantes de Latte and Front, que servirá de
        apoyo para que podáis comentar el curso y resolver dudas entre vosotros.
      </p>
    ),
  },
  {
    title: '¿Qué voy a recibir en el documento final?',
    content: () => (
      <>
        <p>
          Al finalizar las 8 semanas, te enviaré un documento resumen con varios
          puntos que te ayudarán a continuar tu carrera profesional:
        </p>
        <ul>
          <li>
            <p>
              <i>Feedback</i> de tu proyecto final y conceptos que debes
              continuar reforzando.
            </p>
          </li>
          <li>
            <p>
              Hoja de ruta <b>personalizada</b> para el futuro en la que te
              indicaré los siguientes pasos para que puedas seguir creciendo dentro del <i>frontend</i>.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    title:
      'Ahora mismo no tengo mucho tiempo… ¿Qué dedicación necesita Latte and Front?',
    content: () => (
      <>
        <p>
          Los capítulos no son muy extensos, pero sí quiero que los leas
          detenidamente para asimilar todas las ideas. Te recomiendo dedicar 2
          horas al día, aunque lo ideal serían 3 horas.
        </p>
        <p>
          ¡Me encantaría verte dentro! Pero si ves que ahora no es el momento,
          apúntate a la lista de espera para la siguiente edición, enviando un
          correo a:{' '}
          <strong>
            <a href="mailto:info@latteandcode.com">info@latteandcode.com</a>
          </strong>
        </p>
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 16 }}>Preguntas sobre Latte and Front</h1>
      <p style={{ marginBottom: 32 }}>
        Gerardo al habla, para que no te quedes ninguna duda dentro <span role="img" aria-label="guiño">😉</span>
      </p>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
      <p style={{ marginTop: 48, marginBottom: 16 }}>
        Si tu duda es tan especial que no se encuentra entre las “preguntas
        frecuentes”, puedes escribir a:{' '}
        <a href="mailto:info@latteandcode.com">info@latteandcode.com</a>.
      </p>
      <p>¡Pregunta cualquier cosa que quieras tener clara antes de empezar!</p>
    </Container>
  </Section>
);

export default Faq;
