import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';

const Price = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="sobre-mi" accent>
        <StyledContainer>
          <div>
            <h1 style={{ marginBottom: 40 }}>Mentoría de 8 semanas</h1>
            <Button href="#inscribeme">529 € (IVA incluido)</Button>
            <p style={{ marginTop: 20 }}>
              He decidido que las <b>plazas disponibles sean limitadas</b> para
              que la mentoría sea 100% productiva
            </p>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const StyledContainer = styled(Container)`
  position: relative;
  & > div {
    position: relative;
    z-index: 1;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  p {
    color: #000;
  }
`;

const Button = styled(AnchorLink)`
  display: inline-block;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: ${props => props.theme.color.secondary};
  font-size: 24px;
  text-decoration: none;
  border-radius: 4px;
`;

const Art = styled.figure`
  width: 500px;
  position: absolute;
  top: -60%;
  left: -10%;
  z-index: 0;

  @media (max-width: ${props => props.theme.screen.lg}) {
    right: 65%;
    left: auto;
    top: auto;
    bottom: -60%;
    width: 300px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default Price;
