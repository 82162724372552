import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import GithubIcon from '@static/icons/github.svg';
import LinkedinIcon from '@static/icons/linkedin.svg';
import TwitterIcon from '@static/icons/twitter.svg';
import YoutubeIcon from '@static/icons/youtube.svg';
import bubbleLightImage from '@images/art/bubble_light.gif';

const SOCIAL = [
  {
    icon: GithubIcon,
    link: 'https://github.com/ger86'
  },
  {
    icon: LinkedinIcon,
    link: 'https://www.linkedin.com/in/gerardofernandezmoreno/'
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/latteandcode'
  },
  {
    icon: YoutubeIcon,
    link: 'https://youtube.com/c/latteandcode'
  }
];

const Footer = () => (
  <>
    <Claim>
      Prepárate para el futuro, <span>no para mañana</span>
    </Claim>
    <Art>
      <ArtImage
        src={bubbleLightImage}
      />
    </Art>
    <FooterWrapper>
      <StyledContainer>
        <Copyright>
          <Brand>Latte and Front</Brand>
          <span>
            Illustrations by
            {` `}
            <ExternalLink href="https://twitter.com/diana_valeanu">
              @diana_valeanu
            </ExternalLink>
          </span>
        </Copyright>
        <SocialIcons>
          {SOCIAL.map(({ icon, link }) => (
            <ExternalLink key={link} href={link}>
              <img src={icon} alt="link" />
            </ExternalLink>
          ))}
        </SocialIcons>
      </StyledContainer>
    </FooterWrapper>
    <MobileContact>
      <MobileContactButton href="#inscribeme">
        <span>Solicita información</span>
      </MobileContactButton>
    </MobileContact>
  </>
);

const Brand = styled.h2`
  font-family: ${props => props.theme.font.logo};
`;

const ArtImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: auto;
  margin-bottom: -16px;

  @media (min-width: ${props => props.theme.screen.sm}) {
    width: 400px;
  }
`;

const Claim = styled.h2`
  margin-top: 48px;
  text-align: center;
  font-weight: 600;

  span {
    text-decoration: line-through;
  }
`;

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }

  span {
    font-size: 14px;
  }
`;

const Art = styled.div`
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

const MobileContact = styled.div`
  height: 70px;
  display: flex;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1001;
  width: 100%;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
}
`
const MobileContactButton = styled(AnchorLink)`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
  color: #ffffff;
  background-color: ${props => props.theme.color.secondary};
  font-size: 20px;
  text-align: center;
  text-decoration: none;

  span {
    width: 100%;
    padding: 16px;
  }
`;


export default Footer;
