import React from 'react';
import styled from 'styled-components';
import { Section, Container } from '@components/global';
import meImage from '@images/art/me.jpg';
import andreaImage from '@images/art/andrea.jpg';

const AboutMe = () => (
  <StyledSection id="sobre-mi">
    <StyledContainer>
      <Art>
        <Image src={meImage} />
      </Art>
      <MeWrapper>
        <h2 style={{ marginBottom: 16 }}>
          ¿Quién soy yo para hablarte de Javascript?
        </h2>
        <p>
          Soy Gerardo, estudié informática y matemáticas y soy inquieto por
          naturaleza. Me encanta aprender cosas nuevas y los proyectos donde
          superarme a mí mismo. También tengo un perro gigante (Simba{' '}
          <span role="img" aria-label="león">
            🦁
          </span>
          ), toco la guitarra y soy de esos tipos que prefieren un libro de
          papel al Kindle.
        </p>
        <p>
          Actualmente desarrollo en dos tecnologías: ReactJS y Symfony y me
          encanta compartir todo lo que voy aprendiendo sobre ellas.
        </p>
        <p>
          De hecho puede que alguna de mis{' '}
          <a
            href="https://github.com/ger86/recetas-javascripteras"
            target="_blank"
            rel="noreferrer"
          >
            "recetas javascripteras"
          </a>{' '}
          haya llegado hasta a ti algún miércoles.
        </p>
        <p>
          Si tienes curiosidad por verme en acción te invito a que me veas en
          acción visitando mi canal de{' '}
          <a
            href="https://www.youtube.com/c/latteandcode"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Youtube: Latte and Code
          </a>
        </p>
        <p>
          En mi{' '}
          <a href="https://github.com/ger86" target="_blank" rel="noreferrer">
            GitHub
          </a>{' '}
          podrás encontrar el código que complementa el material que creo para
          mi canal de Youtube y mi blog sobre desarrollo web en Medium.
        </p>
      </MeWrapper>
    </StyledContainer>
    {/* <Container style={{ marginTop: 48 }}>
      <h2 style={{ marginBottom: 16 }}>Colabora Andrea Soto Abajo</h2>
      <AndreaWrapper>
        <AndreaImageWrapper>
          <Image src={andreaImage} />
        </AndreaImageWrapper>
        <div>
          <p>
          Emprendedora y amante de los proyectos con alma. Confío en la formación como la mayor evolución, por ello, además de estar en continuo aprendizaje, comparto mis experiencias y transmito conocimiento siempre que puedo.
          </p>
          <p>
          Desde hace mucho tiempo vi la necesidad de encontrar un equilibrio entre la productividad y el bienestar. Te daré consejos, trucos y herramientas para aumentar tu foco y aprovechar los minutos al máximo, sin olvidar la importancia del autocuidado tanto a nivel físico como mental y emocional.    
          </p>
        </div>
      </AndreaWrapper>
    </Container> */}
  </StyledSection>
);

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

const StyledSection = styled(Section)`
  padding-bottom: 0;
`;

const StyledContainer = styled(Container)`
  display: flex;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
    flex-wrap: wrap;
  }

  p {
    margin-bottom: 16px;
    a {
      color: ${props => props.theme.color.black.light};
    }
  }
`;

const Art = styled.div`
  flex: 0 0 550px;
  max-width: 550px;
  margin-right: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const MeWrapper = styled.div`
  @media (max-width: ${props => props.theme.screen.md}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
  }
`;

const AndreaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  p {
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    flex-wrap: nowrap;
  }
`;

const AndreaImageWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 32px;

  @media (min-width: ${props => props.theme.screen.md}) {
    flex: 0 0 calc(350px);
    max-width: calc(350px);
    margin-left: 32px;
    margin-bottom: 0;
  }
`;

export default AboutMe;
