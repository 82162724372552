import React from 'react';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Header from '@sections/Header';
import Discover from '@sections/Discover';
import AboutMe from '@sections/AboutMe';
import Information from '@sections/Information';
import Price from '@sections/Price';
import Faq from '@sections/Faq';
import Contact from '@sections/Contact';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <>
    <Layout>
      <Navbar />
      <Header />
      <Discover />
      <Information />
      <Price />
      <AboutMe />
      <Faq />
      <Contact />
      <Footer />
    </Layout>
  </>
);

export default IndexPage;
