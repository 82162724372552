import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import icon from '../../images/icons/check.png';

const Discover = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="descubre">
        <Container>
          <p>
            Una formación donde mejorar tus conocimientos y habilidades{' '}
            <strong>del desarrollo frontend</strong>, en un formato dinámico,
            práctico y que se adapta a tus necesidades.
          </p>
          <p style={{ marginTop: 40 }}>
            Si quieres seguir mejorando tus conocimientos y dar un empujón a tu
            carrera...
          </p>
          <p style={{ marginTop: 40, marginBottom: 80 }}>
            <strong>¡Te doy la bienvenida a Latte and Front!</strong>
          </p>
          <Grid>
            <div>
              <h2>Es para ti si...</h2>
              <EventarteUl>
                <li>
                  <p>
                    Has oído hablar de Javascript y quieres aprender más (¡y
                    bien!).
                  </p>
                </li>
                <li>
                  <p>
                    Quieres reforzar tus conocimientos de <i>front</i> sin
                    invertir mucho tiempo y dinero.
                  </p>
                </li>
                <li>
                  <p>
                    Has estado en un curso o <i>bootcamp</i> pero{' '}
                    <b>no sabes cómo ordenar todo lo que has aprendido</b>:
                    tienes un “cacao maravillao” en tu cabeza.
                  </p>
                </li>
                <li>
                  <p>
                    Has oído que React “mola mucho” pero no sabes ni por dónde
                    empezar.
                  </p>
                </li>
                <li>
                  <p>
                    Quieres tener una base sólida de Javascript para poder
                    seguir por tu cuenta.
                  </p>
                </li>
              </EventarteUl>
              <p style={{ marginTop: 20 }}>¡Éste es tu curso!</p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>No es para ti si...</h2>
              <EventarteUl>
                <li>
                  <p>No has trabajado nunca con HTML y CSS.</p>
                </li>
                <li>
                  <p>Estás buscando un curso avanzado de Javascript.</p>
                </li>
                <li>
                  <p>
                    Ya conoces React y necesitas profundizar para saltar al
                    nivel Senior.
                  </p>
                </li>
              </EventarteUl>
            </div>
          </Grid>
          <WriteUs>
            <span role="img" aria-label="Sonrisa">
              😁
            </span>{' '}
            Si todavía no sabes si es para ti,{' '}
            <AnchorLink href="#inscribeme">escríbeme</AnchorLink> y te enviaré
            un ejercicio para conocer tu nivel.
          </WriteUs>
          <div>
            <div>
              <h2 style={{ marginBottom: 40 }}>
                ¿Qué vas a ver en Latte and Front?
              </h2>
            </div>
            <Topics>
              <div>
                <div>
                  <h3 style={{ marginBottom: 20 }}>Javascript</h3>
                  <p style={{ marginBottom: 40 }}>
                    <strong>Objetivo</strong>: Asimila y comprende los conceptos
                    clave.
                  </p>
                  <EventarteUl>
                    <li>
                      <p>Tipos principales de Javascript.</p>
                    </li>
                    <li>
                      <p>Cómo trabajar con objetos en Javascript.</p>
                    </li>
                    <li>
                      <p>Scope y contextos de ejecución.</p>
                    </li>
                    <li>
                      <p>
                        Declarando variables con <em>var, let y const.</em>
                      </p>
                    </li>
                    <li>
                      <p>
                        La variable <i>this</i>.
                      </p>
                    </li>
                    <li>
                      <p>Código asíncrono: promesas.</p>
                    </li>
                    <li>
                      <p>Javascript en el navegador.</p>
                    </li>
                    <li>
                      <p>Webpack.</p>
                    </li>
                  </EventarteUl>
                </div>
              </div>
              <div>
                <div>
                  <h3 style={{ marginBottom: 20 }}>React JS</h3>
                  <p style={{ marginBottom: 40 }}>
                    <strong>Objetivo</strong>: Aprende a construir una
                    aplicación web desde cero.
                  </p>
                  <EventarteUl>
                    <li>
                      <p>¿Qué es ReactJS?</p>
                    </li>
                    <li>
                      <p>Componentes y propiedades</p>
                    </li>
                    <li>
                      <p>
                        Estado interno de los componentes. El hook{' '}
                        <i>useState</i>.
                      </p>
                    </li>
                    <li>
                      <p>
                        El hook <i>useEffect</i>.
                      </p>
                    </li>
                    <li>
                      <p>React Router V6 y autenticación</p>
                    </li>
                    <li>
                      <p>
                        Formularios. El hook <i>useReducer</i>.
                      </p>
                    </li>
                    <li>
                      <p>Estilos y styled components.</p>
                    </li>
                    <li>
                      <p>Optimización básica.</p>
                    </li>
                    <li>
                      <p>🎁 Librerías molonas para desarrollar.</p>
                    </li>
                  </EventarteUl>
                </div>
              </div>
              <div>
                <div>
                  <h3 style={{ marginBottom: 20 }}>¡Más que código!</h3>
                  <p style={{ marginBottom: 40 }}>
                    <strong>Objetivo</strong>: Cuídate y aumenta tu
                    productividad.
                  </p>
                  <EventarteUl>
                    <li>
                      <p>Organización y productividad.</p>
                    </li>
                    <li>
                      <p>¡Trabaja tu marca personal!</p>
                    </li>
                    <li>
                      <p>Cómo orientar tu futuro laboral.</p>
                    </li>
                    <li>
                      <p>¿Quieres ser freelance? Primeros pasos.</p>
                    </li>
                    <li>
                      <p>
                        Elimina tus bloqueos mentales para volar más alto{' '}
                        <span role="img" aria-label="cohete">
                          🚀
                        </span>
                      </p>
                    </li>
                  </EventarteUl>
                </div>
              </div>
            </Topics>
            <FinalText>
              Y para terminar... ¡aplica todos estos conocimientos en el
              <b> proyecto final!</b>
            </FinalText>
          </div>
        </Container>
      </Section>
    )}
  />
);

const FinalText = styled.h3`
  margin-top: 32px;
  text-align: center;
  ${props => props.theme.font_size.large}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 64px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 48px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const EventarteUl = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 2rem;
    background-image: url(${icon});
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 0 3px;
    margin-bottom: 20px;
    &:last-child: {
      margin-bottom: 0;
    }
    p {
      ${props => props.theme.font_size.xsmall};
    }
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const WriteUs = styled.div`
  margin-bottom: 120px;
  font-size: 22px;

  a {
    color: #000;
    font-weight: bold;
  }
`;

const Topics = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.screen.md}) {
    flex-wrap: nowrap;
  }

  & > div {
    flex: 0 0 calc(100%);
    margin-bottom: 32px;

    @media (min-width: ${props => props.theme.screen.md}) {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
      margin-bottom: 0;
    }

    & > div {
      @media (min-width: ${props => props.theme.screen.md}) {
        padding-right: 40px;
      }
    }
  }
`;

export default Discover;
